<template>
<div>
  <Header/> 
  <b-container 
    style=" padding-top:70px;"
  > 
    <b-row>
      <b-col>
        <h1>
          施設新規登録
        </h1>
      </b-col>
    </b-row>

      <b-row 
        class="mt-2"
      > 
        <b-col 
          md="1" 
          cols="3"
        >
          施設名：
        </b-col>
        <b-col 
          md="5" 
          cols="9"
        >
          <b-form-input 
            id="FacilityName"  
            v-model="$v.facility.facilityName.$model"
            @blur="$v.facility.facilityName.$touch()"
          />
          <span 
            v-if="$v.facility.facilityName.$error"
          >
            <small 
              class="text-danger" 
              v-if="!$v.facility.facilityName.required"
            >
              施設名を入力してください
            </small>
          </span>
        </b-col>
      </b-row>

      <b-row 
        class="mt-1"
      >
        <b-col 
          md="1" 
          cols="3"
        >
          住所：
        </b-col>
        <b-col 
          md="5" 
          cols="9"
        >
          <b-form-input
            type="text" 
            id="address"
            v-model="$v.facility.address.$model"
            @blur="$v.facility.address.$touch()"
            @change="getLatLng"
          />
          <span 
            v-if="$v.facility.address.$error"
          >
            <small 
              class="text-danger" 
              v-if="!$v.facility.address.required"
            >
              住所を入力してください
            </small>
          </span>
        </b-col>
      </b-row>

      <b-row 
        class="mt-2"
      >
        <b-col 
          md="1" 
          cols="3"
        >
          大区分：
        </b-col >
        <b-col 
          md="5" 
          cols="9"
        >
          <select 
            class="form-select" 
            name="facilityType"
            v-model="$v.facility.facilityType.$model"
            @blur="$v.facility.facilityType.$touch()"
          >
            <option 
              value=""
            >
              選択してください
            </option>
            <option 
              v-for="facilityType in facilityTypes"
              :key="facilityType.inClassificationID"
              :value="facilityType"
            >
              {{ facilityType.vcClassificationName }}
            </option>
          </select>
          <span 
            v-if="$v.facility.facilityType.$error"
          >
            <small 
              class="text-danger" 
              v-if="!$v.facility.facilityType.required"
            >
              施設区分を選択してください
            </small>
          </span>
        </b-col> 
      </b-row>

      <b-row 
        v-if="middleFacility"
        class="mt-2"
      >
        <b-col 
          md="1" 
          cols="3"
        >
          中区分：
        </b-col >
        <b-col 
          md="5" 
          cols="9"
        >
          <select 
            class="form-select" 
            name="facilityType"
            v-model="facility.middleFacilityType"
          >
            <option 
              value=""
            >
              選択してください
            </option>
            <option 
              v-for="middleType in selectMiddleTypes" 
              :key="'middleFacilityType-' + middleType.inClassificationID"
              :value="middleType"
            >
              {{ middleType.vcMiddleClassName }}
            </option>
          </select>
        </b-col>
      </b-row>

      <b-row 
        class="mt-2"
      >
        <dl 
          class="row mt-1"
        >
          <b-col 
            cols="3" 
            md="1"
          >
            <dd>
              タグ：
            </dd>
          </b-col>
          <b-col 
            cols="9" 
            md="5"
          >
            <dd 
              class="col-md-auto" 
              v-if="facilityTags.length !== 0"
            >
              <div 
                v-for="facilityTag in facilityTags"
                :key="facilityTag.vcTagName + facilityTag.insort"
                class="text-nowrap form-check-inline"
              >
                <input
                  name="tags"
                  v-model="facility.tags"
                  class="form-check-input" 
                  type="checkbox"  
                  :id="facilityTag.vcTagName"
                  :value="facilityTag"
                >
                <label 
                  class="form-check-label" 
                  :for="facilityTag.vcTagName"
                >
                  {{ facilityTag.vcTagName }}
                </label>  
              </div>
            </dd>
            <dd 
              class="col-md-auto" 
              v-else
            >
              {{ message.tag }}
            </dd>
          </b-col>
        </dl>   
      </b-row>

      <dl 
        class="row mt-2" 
        v-if="facility.isTrain"
      >
        <b-col 
          cols="3" 
          md="1"
        >
          <dd>
            路線：
          </dd>
        </b-col>
        <b-col 
          cols="9" 
          md="5"
        >
          <dd 
            class="col-md-auto" 
            v-if="trainDatas.length !== 0"
          >
            <div 
              v-for="trainData in trainDatas" 
              :key="trainData.vcKindaikaRouteName + trainData.iRouteNo" 
              class="text-nowrap form-check-inline"
            >
              <input 
                name="train"  
                v-model="facility.trainInfo"
                class="form-check-input" 
                type="checkbox"
                :id="trainData.vcKindaikaRouteName" 
                :value="trainData"
              >
              <label 
                class="form-check-label" 
                :for="trainData.vcKindaikaRouteName"
              >
                {{ trainData.vcKindaikaRouteName }}
              </label>
            </div>
          </dd>
          <dd 
            class="col-md-auto" 
            v-else
          >
            {{ message.train }}
          </dd>
        </b-col>
      </dl> 
      
      <b-row 
        class="mt-2"
      >
        <div 
          id="map" 
          style="height:400px; width:100%;" 
          class="mx-auto"
        ></div>
      </b-row>

      <b-row 
        class="mt-2"
      >
        <b-col>
          <b-button 
            variant="success" 
            @click="addForm"
          >
            ＋追加情報
          </b-button>
        </b-col>
      </b-row>

      <b-tabs 
        class="mt-2"
      >
        <b-tab 
          title="代表情報"
        >
          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              緯度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input
                type="text" 
                id="lat"
                v-model="$v.facility.lat.$model"
                @blur="$v.facility.lat.$touch()"
              />
              <span 
                v-if="$v.facility.lat.$error"
              >
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lat.required"
                >
                  緯度を入力してください
                </small>
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lat.validInteger"
                >
                  半角数字、「.」「-」のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              経度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text" 
                id="lng"
                v-model="$v.facility.lng.$model"
                @blur="$v.facility.lng.$touch()"
              />
              <span 
                v-if="$v.facility.lng.$error"
              >
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lng.required"
                >
                  経度を入力してください
                </small>
                <small 
                  class="text-danger" 
                  v-if="!$v.facility.lng.validInteger"
                >
                  半角数字、「.」「-」のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2" 
            v-if="role == 0"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              補正値：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text" 
                id="correctionValue"  
                v-model="$v.facility.correctionValue.$model"
                @blur="$v.facility.correctionValue.$touch()"
              />
              <span 
                v-if="$v.facility.correctionValue.$error"
              >
                <small 
                  class="text-danger"  
                  v-if="!$v.facility.correctionValue.validInteger"
                >
                  半角数字、「.」「-」のみ入力可能です。
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              代表施設備考：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-textarea 
                rows="3"
                max-rows="6" 
                id="remarks"
                v-model="facility.remarks"
              />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab 
          v-for="(detail, index) in details" 
          :key="'detail' + index"
          :title="details[index].detailActive ?'追加情報' +(detail.id+1) : ''"
        >
          <div 
            v-if="details[index].detailActive" 
            class="mt-2"
          >
            <div>
              <b-row class="mt-2">            
                <b-col md="1" cols="3">
                  名称：
                </b-col>
                <b-col md="5" cols="9">
                  <b-form-input
                    type="text"  
                    v-model="detail.detailName"
                  />
                  <span v-if="detail.validDetailName">
                    <small class="text-danger" >
                      {{detail.validDetailName}}
                    </small>
                  </span>
                </b-col>

                <b-col 
                  style="text-align:right;" 
                  v-if="role === '0'"
                >
                  <b-button 
                    variant="danger" 
                    @click="deleteForm(index,detail.id)"
                  >
                    削除
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col md="1" cols="3">
                    緯度：
                </b-col>
                <b-col md="5" cols="9">
                  <b-form-input
                    type="text" 
                    v-model="detail.lat"
                  />
                  <span v-if="detail.validDetailLat">
                    <small class="text-danger" >
                      {{detail.validDetailLat}}
                    </small>
                  </span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col md="1" cols="3">
                  経度：
                </b-col>
                <b-col md="5" cols="9">
                  <b-form-input 
                    type="text" 
                    v-model="detail.lng"
                  />
                  <span v-if="detail.validDetailLng">
                    <small class="text-danger" >
                      {{detail.validDetailLng}}
                    </small>
                  </span>
                </b-col>
              </b-row>

              <b-row class="mt-2" v-if="role == 0">
                <b-col md="1" cols="3">
                  補正値：
                </b-col>
                <b-col md="5" cols="9">
                  <b-form-input 
                    type="text"   
                    v-model="detail.correctionValue"
                  />
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col md="1" cols="3">
                  追加情報備考：
                </b-col>
                <b-col md="5" cols="9">
                  <b-form-textarea 
                    rows="3"
                    max-rows="6" 
                    id="remarks"
                    v-model="detail.remarks"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <FacilityConfirmModal
        :facility="facility" :details.sync="details"
      />
      
      <b-row class="mt-3">
        <b-button  
          @click="postFacilityModel"
          variant="primary" 
          style="width:50%;" 
          class="btn btn-block mx-auto" 
        >
          施設を登録する
        </b-button>
      </b-row>
  </b-container>  
</div>  
</template>

<script>
import Header from '../../components/Header.vue'
import FacilityConfirmModal from '../../components/FacilityConfirmModal.vue'
import { Apimixin } from '../../mixins/api'
import { Maploader } from '../../mixins/mapload'
import { helpers, required } from "vuelidate/lib/validators"
const validInteger = helpers.regex('validInteger', /^[0-9.-]+$/)

export default {
  title: '施設新規登録',
  mixins:[Apimixin, Maploader],
  data() {
    return {
      detailsID:0,
      role: '',
      mappins: [],
      middleFacility:false,
      selectMiddleTypes:[],
      facilityDetail:"",
      facilityDetailID:[],
      updateFacility:false,
      facility: {
        facilityType: [],
        middleFacilityType:'',
        facilityName: '',
        address: '',
        tags:[],
        lat: '',
        lng: '',
        correctionValue: '',
        remarks: '',
        trainInfo:[],
        routeName:'',
        isActive:false,
        isTrain:false
      },
      details:[
        {
          id: 0,
          detailName: '',
          lat: '',
          lng: '',
          correctionValue: '',
          remarks:'',
          pinID:'',
          detailActive:false,
          pin:false,
          validError:false,
          validDetailName:'',
          validDetailLat:'',
          validDetailLng:''
        }
      ] ,
    validLatLng:/^[0-9.-]+$/
    }
  },
  components: {
    Header,
    FacilityConfirmModal
  },
  created() {
    //デフォルトのピン位置設定（デフォルトは都庁前）
    this.facility.lat = 35.689613888888886
    this.facility.lng = 139.6916338888889

    //施設区分情報取得
    this.getFacilityType();

  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    //マップ描画
    this.mapInit(this.facility.lat, this.facility.lng);
    
    if(this.facility.isTrain) {
      this.getTrainInfo();
    }

  },
  methods: {
    //施設情報の登録確認モーダル
    postFacilityModel(){
      this.$v.$touch();
      if(!this.$v.$invalid) {
        let result = true
        for(let i = 0;i < this.details.length;i++) {
          if(this.details[i].detailActive) { 
            if(this.details[i].validDetailName != '') {
              result = false
              this.details[i].validDetailName = '名称を入力してください。'
            } 
            if(this.details[i].validDetailLat != '') {
              result = false
              this.details[i].validDetailLat = '緯度を入力してください。'
            } 
            if(this.details[i].validDetailLng != '') {
              result = false
              this.details[i].validDetailLng = '経度を入力してください。'
            } 
          }
        }
        if(result) {
          this.$bvModal.show('FacilityConfirmModal')
        } else {
          this.$swal({
            title: `追加情報に未入力項目、もしくは入力不備があります。`,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '閉じる',
          })
        }
      } else {
        this.$swal({
          title: `代表情報に未入力項目、もしくは入力不備があります。`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '閉じる',
        })
      }
    },


    //付帯情報入力フォーム追加
    addForm() {
      if(this.details[0].detailActive) {
        this.detailsID++
        const additionalForm = {
          id:this.detailsID,
          detailName: "",
          lat: this.facility.lat,
          lng: this.facility.lng,
          correctionValue: "",
          remarks:"",
          detailActive:true,
          pinID:'',
          pin:false,
          validDetailName:'',
          validDetailLat:'',
          validDetailLng:''
        }
        this.details.push(additionalForm)
      } else {
        this.details[0].detailActive = true
        this.details[0].lat = this.facility.lat
        this.details[0].lng = this.facility.lng
      }
      
    },
    //追加情報入力フォーム削除
    deleteForm(index,id){
      if(index !== 0) {
        if(confirm('追加情報入力フォームを削除しますか？')) {
          for(let i = 0; i < this.mappins.length;i++) {
            if(this.mappins[i]._options.id === id) {
                this.mappins[i].setVisible(false)
              }
            }
          this.mappins.splice(index,1)
        }
        this.details[index].detailName = '';
        this.details[index].lat = '';
        this.details[index].lng = '';
        this.details[index].pin = false;
        this.details[index].detailActive = false;
        this.details[index].validDetailName = ''
        this.details[index].validDetailLat = ''
        this.details[index].validDetailLng = ''
        this.details.splice(index,1)
      } else {
      if(confirm('追加情報入力フォームを削除しますか？')) {
        for(let i = 0; i < this.mappins.length;i++) {
          if(this.mappins[i]._options.id === id) {
              this.mappins[i].setVisible(false)
            }
        }
          this.mappins.splice(0,1)
          this.details[0].detailName = '';
          this.details[0].lat = '';
          this.details[0].lng = '';
          this.details[0].pin = false;
          this.details[0].detailActive = false;
          this.details[0].validDetailName = ''
          this.details[0].validDetailLat = ''
          this.details[0].validDetailLng = ''
        }
      }
      
    },
    //施設中区分取得
    getMiddleFacility() {
      for(let i = 0;i < this.middleFacilityTypes.length; i++) {
        if(this.middleFacilityTypes[i].inParentClassID === this.facility.facilityType.inClassificationID) {
          this.middleFacility = true
          this.selectMiddleTypes.push(this.middleFacilityTypes[i])
        }
      }
    }
  },
  
  watch: {
    details: {
      handler:function() {
        for(let i = 0; i < this.details.length;i++) {
          if(this.details[i].lat != "") {
            if(this.details[i].detailActive === true) {
              if(this.details[i].pin === false) {
                this.createDetailmappin(
                  '追加情報'+(this.details[i].id+1),this.details[i].id,
                  this.details[i].lat,this.details[i].lng
                )
                this.details[i].pin = true
                this.details[i].pinID = this.mappins.length-1
              }
            }
          }
          if(this.details[i].detailActive === true) {
            if(this.details[i].detailName.length === 0) {
              this.details[i].validDetailName = '名称を入力してください。'
            }else {
              this.details[i].validDetailName = ''
            }
            if(this.details[i].lat.length === 0) {
              this.details[i].validDetailLat = '緯度を入力してください。'
            }else if(!this.validLatLng.test(this.details[i].lat)){
              this.details[i].validDetailLat = '半角数字、「.」「-」のみ入力可能です。'
            }else {
              this.details[i].validDetailLat = ''
            } 
            if(this.details[i].lng.length === 0) {
              this.details[i].validDetailLng = '経度を入力してください。'
            } else if(!this.validLatLng.test(this.details[i].lng)){
              this.details[i].validDetailLat = '半角数字、「.」「-」のみ入力可能です。'
            }else {
              this.details[i].validDetailLng = ''
            }
          }
        }
      },
      deep:true
    },
    'facility.facilityType': function() {
      this.getTag()
      this.facility.tags = [];
      this.middleFacility = false;
      this.selectMiddleTypes = [];
      this.facility.middleFacilityType = "";
      this.getMiddleFacility();
      if(this.facility.facilityType.inClassificationID == 2) {
        this.facility.isTrain = true
      } else {
        this.facility.isTrain = false
        this.facility.trainInfo = []
      }
    },
    
    'facility.facilityName': function() {
        this.trainDatas = [];
        this.getTrainInfo();
    }
  },
    validations: {
    facility: {
      facilityType: {
        required
      },
      facilityName: {
        required
      },
      address: {
        required
      },
      lat: {
        validInteger,
        required
      },
      lng: {
        validInteger,
        required
      },
      correctionValue: {
        validInteger
      }
    }
    
  }
}
</script>


<style scoped>
.body-container {
  min-height: 300px;
  margin-bottom: 60px; 
}

.formLabel {
  display: inline-block;
  width: 140px;
  text-align: right;
}
</style>