<template>
<b-modal
  id="FacilityConfirmModal"
  title="新規施設登録"
  size="lg"
  hide-header-close
  hide-footer

  >
<b-row>                          
  <!-- <div class="row align-items-center">
    <b-col>
      <h1>新規施設登録</h1>
    </b-col>   
    <div class="col-auto"></div>
  </div> -->

    <div >
      <b-row>   
        <b-col md="auto" cols="auto" class="formLabel">
          施設名：
        </b-col> 
        <b-col md="9" cols="auto">
          {{ facility.facilityName }}
        </b-col>
      </b-row>
      <hr>

      <b-row> 
        <b-col md="auto" cols="auto" class="formLabel">
          住所：
        </b-col>
        <b-col md="9" cols="auto">
          {{ facility.address }}
        </b-col>
      </b-row>
      <hr>
      
      <b-row>   
        <b-col md="auto" cols="auto" class="formLabel">
          大区分：
        </b-col>
        <b-col md="9" cols="auto">
          {{ facility.facilityType.vcClassificationName }}
        </b-col>
      </b-row>

      <b-row v-if="facility.middleFacilityType !== ''">
      <hr>
        <b-col md="auto" cols="auto" class="formLabel">
          中区分：
        </b-col>
        <b-col md="9" cols="auto">
          {{ facility.middleFacilityType.vcMiddleClassName }}
        </b-col>
      </b-row>
      <hr>

      <b-row>   
        <b-col md="auto" cols="auto" class="formLabel">
          タグ：
        </b-col>
        <b-col md="9" cols="auto">
          <span v-for="(tag, index) in facility.tags" :key=index>
            #{{ tag.vcTagName }}
          </span>
        </b-col>
      </b-row>
      <hr>

      <b-row class="row mt-2" v-if="facility.trainInfo.length !== 0">
        <b-col md="auto" cols="auto" class="formLabel">
          路線情報：
        </b-col>
        <b-col md="9" cols="auto">
          <span v-for="(trainData, index) in facility.trainInfo" :key=index>
            #{{ trainData.vcKindaikaRouteName }}
          </span>
        </b-col>
        <hr>
      </b-row> 

      <b-tabs>
        <b-tab 
          title="代表情報"
        >
          <b-row>
            <b-col md="auto" cols="auto" class="formLabel">
              緯度：
            </b-col>
            <b-col md="9" cols="auto">
              {{ facility.lat }}
            </b-col>
          </b-row>
          <hr>

          <b-row>
            <b-col md="auto" cols="auto" class="formLabel">
              経度：
            </b-col>
            <b-col md="9" cols="auto">
              {{ facility.lng }}
            </b-col>
          </b-row>
          <hr>

          <b-row>
            <b-col md="auto" cols="auto" class="formLabel">
              補正値：
            </b-col>
            <b-col md="9" cols="auto">
              {{ facility.correctionValue }}
            </b-col>
          </b-row>
          <hr>

          <b-row>
            <b-col md="auto" cols="auto" class="formLabel">
              代表情報備考：
            </b-col>
            <b-col md="9" cols="auto">
              {{ facility.remarks }}
            </b-col>
          </b-row>
        </b-tab>

        <b-tab 
          v-for="(detail, index) in this.details" 
          :key="index"
          :title="detail.detailName"
        >
          <div>
            <div>
              <b-row> 
                <b-col md="auto" cols="auto" class="formLabel">
                  名称：
                </b-col>
                <b-col md="9" cols="auto">
                  {{ detail.detailName}}
                </b-col>
              </b-row>
              <hr>

              <b-row>
                <b-col md="auto" cols="auto" class="formLabel">
                  緯度：
                </b-col>
                <b-col md="9" cols="auto">
                  {{ detail.lat }}
                </b-col>
              </b-row>
              <hr>

              <b-row>
                <b-col md="auto" cols="auto" class="formLabel">
                  経度：
                </b-col>
                <b-col md="9" cols="auto">
                  {{ detail.lng }}
                </b-col>
              </b-row>
              <hr>

              <b-row>
                <b-col md="auto" cols="auto" class="formLabel">
                  補正値：
                </b-col>
                <b-col md="9" cols="auto">
                  {{ detail.correctionValue }}
                </b-col>
              </b-row>
              <hr>

              <b-row>
                <b-col md="auto" cols="auto" class="formLabel">
                  追加情報備考：
                </b-col>
                <b-col md="9" cols="auto">
                  {{ detail.remarks }}
                </b-col>
              </b-row>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-row class="mt-2">
        <b-button 
          @click="postFacility"
          class="btn btn-block"
          variant="primary"
        >
          確定する
        </b-button>
        <!-- <b-button 
          @click="$router.back()" 
          class="mr-2 col-5 col-md-5 btn btn-secondary btn-block"
        >
          修正する
        </b-button> -->
      </b-row>
    </div>
  </b-row>  
</b-modal>  
</template>

<script>
import { Apimixin } from '../mixins/api'

export default{
  mixins:[Apimixin],
  props:{
    facility:Object,
    details:[],
    // tagID:[],
    // routeID:[],
    // stationID:Number,
    // typeID:Number
  },
  data() {
    return {
      tagID:[],
      routeID:[],
      stationID:'',
      typeID:'',
    }
  },

  methods:{
    //施設新規登録
    postFacility() {
      for(let i = 0;i < this.facility.tags.length; i++) {
          this.tagID.push(this.facility.tags[i].inTagID)
        }
      for(let i = 0;i < this.facility.trainInfo.length; i++) {
        this.routeID.push(this.facility.trainInfo[i].iRouteNo)
      }
      if(this.facility.trainInfo.length > 0) {
        this.stationID = this.facility.trainInfo[0].iStationNo
      }
      this.typeID = this.facility.facilityType.inClassificationID
      this.$swal({
        title: '処理中',
        html: '処理終了まで画面はそのままにしてください。', 
        allowOutsideClick : false , 
        showConfirmButton: false, 
        onBeforeOpen: () => {
          this.$swal.showLoading()
        }
      });
      this.postNewFacility()
    }
  }
}


</script>